import React from 'react'
import styled from 'styled-components'

// Import Page Level Components
import FilterGroupComponent from '../../../Organisms/FilterGroupComponent.js'
import CarrierResultsAccordion from '../../../PageComponents/Misc/CarrierResultsAccordion.js'
import CarrierResultsAccordionLink from '../../../PageComponents/Misc/CarrierResultsAccordionLink.js'
import QuoteSummaryGroupComponent from '../../../Organisms/QuoteSummaryGroupComponent.js'

const GContentCarrierResults = styled.div`
  body {
    @import url('https://fonts.googleapis.com/css?family=Teko');
    font-family: 'Teko', sans-serif;
  }
  margin: 0 auto;
  max-width: 1360px;
  background-color: #ffffff;
  // 🖋
  font-family: 'Open Sans';
  // 📰
  display: grid;
  grid-template-rows: minmax(1fr, 1fr) 1fr;
  grid-template-columns: 0.25fr 0.55fr 0.25fr;
  grid-template-areas:
    "sg_filter sg_carrier sg_quote_summary";

  .SGFilter {
    grid-area: sg_filter; // 🗺
    padding-right: 20px;
  }
  .SGCarrier {
    grid-area: sg_carrier; // 🗺
    overflow: auto; 
    .AccordionComponentSelectCarrier {
      margin-right: 20px;
    }
  }
  .SGQuoteSummary {
    grid-area: sg_quote_summary; // 🗺
    padding-left: 10px;
  }
`;

const SGFilter = styled.div`
    padding-right: 8px;
`;
const SGCarrier = styled.div`
  padding-right: 8px;
  padding-left: 8px;
`;
const SGQuoteSummary = styled.div`
  padding-left: 25px;
`;
const SGCarrierTitle = styled.p`
  font-weight: 600;
  color: #666;
  font-size: 14px;
`



const GridComponentSelectCarrier = () => {
  return (

    <GContentCarrierResults>


        <SGFilter>
          <FilterGroupComponent></FilterGroupComponent>
        </SGFilter>
        <SGCarrier>
          <SGCarrierTitle>31 Carriers</SGCarrierTitle>

          <CarrierResultsAccordionLink 
            title="UPS FREIGHT"
            htmlFor="accordion-1"
            image={('https://res.cloudinary.com/dj1xukpnf/image/upload/v1555337662/ups-freight.png')} 
            transitTime="4 Days"
            Direct="Direct"
            estimatedDelivery="Fri, May 3"
            priceNew="$4,000.00"
            priceUsed="$50.00"
            carrierPrice="$227.24"
            />

          <CarrierResultsAccordion 
            title="ROADRUNNER DAWES FREIGHT SYSTEMS INC."
            htmlFor="accordion-2"
            image={('https://res.cloudinary.com/dj1xukpnf/image/upload/v1552681941/road-runner.jpg')} 
            transitTime="4 Days"
            Direct="Direct"
            estimatedDelivery="Fri, May 3"
            priceNew="$6,000.00"
            priceUsed="$50.00"
            carrierPrice="$259.75"
            />

          <CarrierResultsAccordion 
            title="OLD DOMINION FREIGHT LINE"
            htmlFor="accordion-3"
            image={('https://res.cloudinary.com/dj1xukpnf/image/upload/v1555337662/old-dominion.png')} 
            transitTime="5 Days"
            Direct="Direct"
            estimatedDelivery="Mon, May 6"
            priceNew="$2,500.00"
            priceUsed="$50.00"
            carrierPrice="$261.44"
            />

          <CarrierResultsAccordion 
            title="XPO LOGISTICS FREIGHT INC"
            htmlFor="accordion-4"
            image={('https://res.cloudinary.com/dj1xukpnf/image/upload/v1552681945/xpo-logistics.png')} 
            transitTime="4 Days"
            Direct="Direct"
            estimatedDelivery="Fri, May 3"
            priceNew="$4,000.00"
            priceUsed="$50.00"
            carrierPrice="$227.24"
            />

          <CarrierResultsAccordion 
            title="SAIA MOTOR FREIGHT LINE"
            htmlFor="accordion-5"
            image={('https://res.cloudinary.com/dj1xukpnf/image/upload/v1552681943/sala.jpg')} 
            transitTime="3 Days"
            Direct="Direct"
            estimatedDelivery="Thu, May 2"
            priceNew="$0.00"
            priceUsed="$0.00"
            carrierPrice="$303.69"
            />

          <CarrierResultsAccordion 
            title="ESTES EXPRESS"
            htmlFor="accordion-6"
            image={('https://res.cloudinary.com/dj1xukpnf/image/upload/v1555337662/estes-express.jpg')} 
            transitTime="3 Days"
            Direct="Direct"
            estimatedDelivery="Thu, May 2"
            priceNew="$11,500.00"
            priceUsed="$50.00"
            carrierPrice="$404.53"
            />

          <CarrierResultsAccordion 
            title="ABF FREIGHT SYSTEMS"
            htmlFor="accordion-7"
            image={('https://res.cloudinary.com/dj1xukpnf/image/upload/v1555337799/abf.jpg')} 
            transitTime="4 days"
            Direct="Direct"
            estimatedDelivery="Fri, May 3"
            priceNew="$12,500.00"
            priceUsed="$50.00"
            carrierPrice="$483.18"
            />
          <CarrierResultsAccordion 
            title="ESTES EXPRESS"
            htmlFor="accordion-8"
            image={('https://res.cloudinary.com/dj1xukpnf/image/upload/v1555337662/estes-express.jpg')} 
            transitTime="3 days"
            Direct="Direct"
            estimatedDelivery="Thu, May 2"
            priceNew="$11,500.00"
            priceUsed="$50.00"
            carrierPrice="$483.18"
            />
          <CarrierResultsAccordion 
            title="PITT OHIO EXPRESS"
            htmlFor="accordion-9"
            image={('https://res.cloudinary.com/dj1xukpnf/image/upload/v1555337662/pitt-ohio.png')} 
            transitTime="4 days"
            Direct="Indirect"
            estimatedDelivery="Fri, May 3"
            priceNew="$2,500.00"
            priceUsed="$50.00"
            carrierPrice="$469.70"
            />
          <CarrierResultsAccordion 
            title="FORWARD AIR INC"
            htmlFor="accordion-10"
            image={('https://res.cloudinary.com/dj1xukpnf/image/upload/v1555337661/forward-air.png')} 
            transitTime="3 days"
            Direct="Direct"
            estimatedDelivery="Fri, May 3"
            priceNew="$0.00"
            priceUsed="$0.00"
            carrierPrice="$500.14"
            />

        </SGCarrier>
        <SGQuoteSummary>
            <QuoteSummaryGroupComponent></QuoteSummaryGroupComponent>
        </SGQuoteSummary>


    </GContentCarrierResults>

  )
}

export default GridComponentSelectCarrier;